import { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { themeColors, themeIcon } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../store";
import useMobileCheck from "../../../hooks/useMobileChecker";
import api from "../../../services/api";
import KatakomButton from "../../shared/KatakomButton";
import { ButtonGroup } from "../style";
import { addDocumentDopuna, addTempDocumentDopuna, deleteTempDocumentDopuna, resetState } from "../../../store/CaseDopunaReducer";
import KatakomModal from "../../shared/KatakomModal";
import { SuccessMessageModal } from "../../customComponent/SuccessMessageModal";
import { WaitingModal } from "../../customComponent/WaitingModal";
import { ErrorMessageModal } from "../../customComponent/ErrorMessageModal";
import { useNavigate } from "react-router-dom";

interface Props {
    closeModal: () => void;
    caseData: any;
}

export const DopunaModal: FC<Props> = ({ caseData, closeModal }) => {
    console.log("caseData", caseData);
    const isMobile = useMobileCheck();
    const tempDokumenta = useSelector((state: RootState) => state.caseDopuna.tempDokumenta);
    const slucajDokumenta = useSelector((state: RootState) => state.caseDopuna.dokumenta);
    const [draggingOverDocument, setDraggingOverDocument] = useState<string | null>(null);
    const [documentStatus, setDocumentStatus] = useState({
        "401": false,
        "402": false,
        "601": false,
    });
    const [successModalOpen, setSuccessModalOpen] = useState<boolean>(false);
    const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false);
    const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);

    const dispatch: AppDispatch = useAppDispatch();

    let navigate = useNavigate();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const handleDeleteDocument = (vrstaIspraveId: string, name: string) => {
        dispatch(deleteTempDocumentDopuna({ vrstaIspraveId, name }));
        setDocumentStatus({ ...documentStatus, [vrstaIspraveId]: false });
    };

    const handleFileUpload = async (files: FileList | null, vrstaIspraveId: string) => {
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type === "application/pdf") {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async () => {
                        const base64String = reader.result?.toString().split(",")[1];
                        if (base64String) {
                            try {
                                const isSigned = await hasDigitalSignature(base64String);
                                dispatch(
                                    addTempDocumentDopuna({
                                        name: file.name,
                                        file: base64String,
                                        isSigned: Boolean(isSigned),
                                        category: "Kategorija 1",
                                        vrstaIspraveId: vrstaIspraveId,
                                    })
                                );
                                toggleDocumentStatus(vrstaIspraveId);
                            } catch (error) {
                                console.error(error);
                            }
                        } else {
                            console.error("Error while reading file");
                        }
                    };
                    reader.onerror = () => {
                        console.error("Error while reading file");
                    };
                } else {
                    console.error("Please select a PDF file");
                }
            }
        }
    };

    const submitFiles = async () => {
        setWaitingModalOpen(true);
        const documents = [];
        try {
            for (const dok of tempDokumenta) {
                const response = await api.post(`api/v1/send-file-from-lawyers`, {
                    base64Document: dok.file,
                });
                documents.push({ id_prijem_fajl_esalter: response?.data.id_prijem_fajl_esalter, vrstaIspraveId: dok.vrstaIspraveId });
            }

            dispatch(addDocumentDopuna(documents));
        } catch (e) {
            setErrorModalOpen(true);
        } finally {
            setWaitingModalOpen(false);
        }
    };

    const handleFinish = async () => {
        if (slucajDokumenta.length > 0) {
            try {
                const res = await api.post(`api/v1/send-additional-data-from-lawyers?idPrijemEsalter=${caseData.id_prijem_esalter}`, {
                    dokumenta: slucajDokumenta,
                });

                if (res.status !== 200) {
                    setErrorModalOpen(true);
                } else {
                    setSuccessModalOpen(true);
                }
            } catch (e) {
                setErrorModalOpen(true);
            }
        }
    };

    useEffect(() => {
        if (slucajDokumenta.length > 0) {
            handleFinish();
        }
    }, [slucajDokumenta]);

    const handleDragOver = (event: React.DragEvent<HTMLLabelElement>, vrstaIspraveId: string) => {
        event.preventDefault();
        setDraggingOverDocument(vrstaIspraveId);
    };

    const handleDrop = (event: React.DragEvent<HTMLLabelElement>, vrstaIspraveId: string) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        handleFileUpload(droppedFiles, vrstaIspraveId);
        setDraggingOverDocument("");
    };

    const hasDigitalSignature = async (base64EncodedFile: string) => {
        const response = await api.post(`api/v1/has-digital-signature`, {
            base64Document: base64EncodedFile,
        });

        return response?.data;
    };

    const toggleDocumentStatus = (vrstaIspraveId: string) => {
        //@ts-ignore
        setDocumentStatus({ ...documentStatus, [vrstaIspraveId]: !documentStatus[vrstaIspraveId] });
    };
    const isDocumentUploaded = (vrstaIspraveId: string): boolean => {
        return tempDokumenta.some((doc) => doc.vrstaIspraveId === vrstaIspraveId);
    };

    const getUploadedDocuments = (vrstaIspraveId: string) => {
        return tempDokumenta.filter((doc) => doc.vrstaIspraveId === vrstaIspraveId);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLImageElement>, vrstaIspraveId: string) => {
        if (event.key === "Enter") {
            const fileInput = document.getElementById(`fileInput-${vrstaIspraveId}`) as HTMLInputElement | null;
            if (fileInput) {
                fileInput.click();
            }
        }

        const keys = ["401", "402", "601"];
        const currentIndex = keys.indexOf(vrstaIspraveId);

        if (event.key === "ArrowLeft") {
            const newIndex = (currentIndex - 1 + keys.length) % keys.length;
            //@ts-ignore
            imageRefs[keys[newIndex]].current?.focus();
        } else if (event.key === "ArrowRight") {
            const newIndex = (currentIndex + 1) % keys.length;
            //@ts-ignore
            imageRefs[keys[newIndex]].current?.focus();
        }
    };

    const imageRefs = {
        "401": useRef<HTMLImageElement>(null),
        "402": useRef<HTMLImageElement>(null),
        "601": useRef<HTMLImageElement>(null),
    };

    useEffect(() => {
        const img = document.getElementById("firstImageToFocuse") as HTMLImageElement | null;
        img?.focus();
    }, []);

    return (
        <>
            <KatakomModal isOpen={successModalOpen} setIsOpen={setSuccessModalOpen}>
                <SuccessMessageModal
                    closeModal={() => {
                        routeChange("/pocetna");
                        dispatch(resetState());
                        setSuccessModalOpen(false);
                        closeModal();
                    }}
                    message="Успешно сте послали допуну/жалбу!"
                />
            </KatakomModal>

            <KatakomModal isOpen={waitingModalOpen} setIsOpen={setWaitingModalOpen}>
                <WaitingModal message="Захтев се шаље молимо вас сачекајте!" />
            </KatakomModal>

            <KatakomModal isOpen={errorModalOpen} setIsOpen={setErrorModalOpen}>
                <ErrorMessageModal
                    closeModal={() => {
                        routeChange("/pocetna");
                        dispatch(resetState());
                        setErrorModalOpen(false);
                        closeModal();
                    }}
                    message="Дошло је до грешке приликом слања захтева!"
                />
            </KatakomModal>
            <Container>
                <div
                    style={{
                        display: "flex",
                        marginTop: "30px",
                        flexDirection: isMobile ? "column" : "row",
                        justifyContent: isMobile ? "" : "center",
                        alignItems: isMobile ? "center" : "",
                    }}
                >
                    <Label onDragOver={(e) => handleDragOver(e, "401")} onDrop={(e) => handleDrop(e, "401")}>
                        <Image
                            ref={imageRefs["401"]}
                            src={
                                isDocumentUploaded("401")
                                    ? `${themeIcon}/addPdf.png`
                                    : draggingOverDocument === "401"
                                    ? `${themeIcon}/dropPdf.png`
                                    : `${themeIcon}/uploadDocumentPlaceholder.png`
                            }
                            onKeyDown={(e) => handleKeyPress(e, "401")}
                            tabIndex={0}
                            uploadedFile={isDocumentUploaded("401")}
                        />
                        <span>Исправа за упис/промену података у катастру</span>
                        {isDocumentUploaded("401") && (
                            <>
                                {getUploadedDocuments("401").map((document, index) => (
                                    <div key={index} style={{ marginTop: "10px" }}>
                                        <FileName>{document.name}</FileName>
                                        <DeleteImage
                                            style={{ padding: "5px", borderRadius: "5px" }}
                                            src={`${themeIcon}/whiteDelete.png`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDeleteDocument("401", document.name);
                                            }}
                                        />
                                        {document.isSigned ? (
                                            <div style={{ color: "green" }}>Потписан</div>
                                        ) : (
                                            <div style={{ color: "red" }}>Није електронски потписан</div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                        <Input
                            id="fileInput-401"
                            type="file"
                            onChange={(e) => {
                                handleFileUpload(e.target.files, "401");
                            }}
                            accept=".pdf"
                            multiple={true}
                        />
                    </Label>
                    <Label onDragOver={(e) => handleDragOver(e, "402")} onDrop={(e) => handleDrop(e, "402")}>
                        {" "}
                        <Image
                            ref={imageRefs["402"]}
                            src={
                                isDocumentUploaded("402")
                                    ? `${themeIcon}/addPdf.png`
                                    : draggingOverDocument === "402"
                                    ? `${themeIcon}/dropPdf.png`
                                    : `${themeIcon}/uploadDocumentPlaceholder.png`
                            }
                            onKeyDown={(e) => handleKeyPress(e, "402")}
                            tabIndex={0}
                            uploadedFile={isDocumentUploaded("402")}
                        />
                        <span>Жалба на првостепену одлуку катастра</span>
                        {isDocumentUploaded("402") && (
                            <>
                                {getUploadedDocuments("402").map((document, index) => (
                                    <div key={index} style={{ marginTop: "10px" }}>
                                        <FileName>{document.name}</FileName>
                                        <DeleteImage
                                            style={{ padding: "5px", borderRadius: "5px" }}
                                            src={`${themeIcon}/whiteDelete.png`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDeleteDocument("402", document.name);
                                            }}
                                        />
                                        {document.isSigned ? (
                                            <div style={{ color: "green" }}>Потписан</div>
                                        ) : (
                                            <div style={{ color: "red" }}>Није електронски потписан</div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                        <Input
                            id="fileInput-402"
                            type="file"
                            onChange={(e) => {
                                handleFileUpload(e.target.files, "402");
                            }}
                            accept=".pdf"
                            multiple={true}
                        />
                    </Label>
                    <Label onDragOver={(e) => handleDragOver(e, "601")} onDrop={(e) => handleDrop(e, "601")}>
                        {" "}
                        <Image
                            ref={imageRefs["601"]}
                            src={
                                isDocumentUploaded("601")
                                    ? `${themeIcon}/addPdf.png`
                                    : draggingOverDocument === "601"
                                    ? `${themeIcon}/dropPdf.png`
                                    : `${themeIcon}/uploadDocumentPlaceholder.png`
                            }
                            onKeyDown={(e) => handleKeyPress(e, "601")}
                            tabIndex={0}
                            uploadedFile={isDocumentUploaded("601")}
                        />
                        <span>Очитана лична карта странке</span>
                        {isDocumentUploaded("601") && (
                            <>
                                {getUploadedDocuments("601").map((document, index) => (
                                    <div key={index} style={{ marginTop: "10px" }}>
                                        <FileName>{document.name}</FileName>
                                        <DeleteImage
                                            style={{ padding: "5px", borderRadius: "5px" }}
                                            src={`${themeIcon}/whiteDelete.png`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDeleteDocument("601", document.name);
                                            }}
                                        />
                                        {document.isSigned ? (
                                            <div style={{ color: "green" }}>Потписан</div>
                                        ) : (
                                            <div style={{ color: "red" }}>Није електронски потписан</div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                        <Input
                            id="fileInput-601"
                            type="file"
                            onChange={(e) => {
                                handleFileUpload(e.target.files, "601");
                            }}
                            accept=".pdf"
                            multiple={true}
                        />
                    </Label>
                </div>
                <ButtonGroup>
                    <KatakomButton
                        label={"Одустани"}
                        theme="secondary"
                        onClick={() => {
                            closeModal();
                        }}
                    />
                    <KatakomButton
                        label={"Додај"}
                        onClick={() => {
                            if (tempDokumenta.length > 0) {
                                submitFiles();
                            } else {
                                handleFinish();
                            }
                        }}
                    />
                </ButtonGroup>
            </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
`;

const Label = styled.label`
    display: flex;
    flex-direction: column;
    width: 160px;
    margin: 25px;
    text-align: center;
    color: ${themeColors.dopunaModalLabel};

    input[type="file"] {
        display: none;
    }
`;

const Input = styled.input``;

const Image = styled.img<{ uploadedFile: boolean }>`
    width: ${(props) => (props.uploadedFile ? "100px" : "160px")};
    margin-top: ${(props) => (props.uploadedFile ? "19px" : "0px")};
    margin-bottom: ${(props) => (props.uploadedFile ? "19px" : "0px")};
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    &:focus,
    &:hover {
        background-color: ${(props) => (props.uploadedFile ? "none" : ` ${themeColors.dopunaModalImageHover}`)};
        border-radius: ${(props) => (props.uploadedFile ? "none" : `16px`)};
        opacity: ${(props) => (props.uploadedFile ? "1" : `0.7`)};
    }
`;

const DeleteImage = styled.img`
    background-color: ${themeColors.dopunaModalDeleteImageBackgroundColor};
    margin-top: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const FileName = styled.div`
    font-size: 16px;
    font-weight: 500;
    margin-top: 4px;
    width: 160px;
    word-wrap: break-word;
`;
